import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Pin from '@Components/Pin';
import Img from '@UI/Img';
import styles from './InteriorImage.module.css';

import type { HomeIdeaData } from '@Types/Ideas';
import type { FC, HTMLAttributes, MutableRefObject } from 'react';

export interface InteriorImageProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  img: HomeIdeaData;
  analyticsTitle: string;
  refGrid: MutableRefObject<HTMLDivElement>;
}

const InteriorImage: FC<InteriorImageProps> = (props) => {
  const { className, img, refGrid, analyticsTitle, ...restProps } = props;

  return (
    <>
      <Img
        {...restProps}
        className={cn(styles.imageWrapper, className)}
        cnImage={styles.image}
        src={img.src}
        alt={img.alt}
      />
      {img.pins.map((pin) => (
        <Pin
          key={`${pin.product.id}${pin.coords}`}
          pinCoords={pin.coords}
          product={pin.product}
          refGrid={refGrid}
          analyticsTitle={analyticsTitle}
        />
      ))}
    </>
  );
};

export default memo(InteriorImage);
