import { Arrow, cn } from '@divlab/divanui';

import styles from './NavSideArrows.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface NavSideArrowsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onPrev?: (e: MouseEvent) => void;
  onNext?: (e: MouseEvent) => void;
  view?: 'circle-chevron' | 'chevron';
  inversed?: boolean;
  cnArrows?: string;
}

const NavSideArrows: FC<NavSideArrowsProps> = (props) => {
  const { view, inversed, onPrev, onNext, cnArrows, ...restProps } = props;

  return (
    <div {...restProps}>
      <Arrow
        position='left'
        view={view}
        inversed={inversed}
        className={cn(styles.prev, cnArrows)}
        onClick={onPrev}
      />

      <Arrow
        position='right'
        view={view}
        inversed={inversed}
        className={cn(styles.next, cnArrows)}
        onClick={onNext}
      />
    </div>
  );
};

export default NavSideArrows;
