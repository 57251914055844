import { memo, useCallback, lazy, useState } from 'react';
import { cn } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import Suspense from '@Components/Suspense';
import useCountryBasedData from '@Hooks/useCountryBasedData';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import useMedias from '@Hooks/useMedias';
import { useRequest } from '@Contexts/Request';
import styles from './Pin.module.css';

import type { FC, HTMLAttributes, MouseEvent, MutableRefObject, CSSProperties } from 'react';
import type { ProductData } from '@Types/Product';

export interface PinProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
  className?: string;
  pinCoords: [number, number];
  product: ProductData;
  refGrid: MutableRefObject<HTMLDivElement>;
  analyticsTitle: string;
  onClick?: (e: MouseEvent) => void;
  onClickProductLink?: () => void;
  inNewTab?: boolean;
  analyticsLabel?: string;
}

const IdeasPopup = lazy(() => import('./elements/IdeasPopup'));
const halfPinWidth = 17;

const Pin: FC<PinProps> = (props) => {
  const {
    className,
    pinCoords,
    product,
    refGrid,
    analyticsTitle,
    analyticsLabel,
    inNewTab,
    onClick,
    onClickProductLink,
    ...restProps
  } = props;
  const [visible, setVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState<CSSProperties>();
  const { isMobile } = useMedias();
  const { country } = useRequest();

  const popupWidth = useCountryBasedData({
    UZB: 325,
    default: 289,
  });
  const halfPopupWidth = Math.floor(popupWidth / 2);
  const [left, top] = pinCoords;
  const priceWithoutCurrency = country === 'UZB';

  const handleOpenInDesktop = useCallback(
    (e: MouseEvent) => {
      const currentRect = e.currentTarget.getBoundingClientRect();
      const currentGrid = refGrid.current.getBoundingClientRect();

      if (currentGrid.left + halfPopupWidth + halfPinWidth > currentRect.left) {
        const posLeft = currentGrid.left + halfPopupWidth - halfPinWidth - currentRect.left;

        setPopupPosition({ left: `${Math.floor(posLeft)}px` });
      }

      if (currentRect.right - halfPinWidth + halfPopupWidth > currentGrid.right) {
        const posRight = currentRect.right - currentGrid.right - halfPopupWidth + halfPinWidth;

        setPopupPosition({ right: `${Math.floor(posRight)}px` });
      }

      setVisible((prev) => !prev);
    },
    [refGrid, halfPopupWidth],
  );

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const refPopup = useOnClickOutside<HTMLDivElement>(handleClose, !visible);

  const handleOpenInMobile = useCallback(() => {
    ModalWindows.open('Idea', {
      product,
      withoutCurrency: priceWithoutCurrency,
      closeModal: handleClose,
      onClickProductLink,
      listTitle: analyticsTitle,
      analyticsLabel,
      inNewTab,
    });
    setVisible((prev) => !prev);
  }, [
    product,
    priceWithoutCurrency,
    handleClose,
    onClickProductLink,
    analyticsTitle,
    analyticsLabel,
    inNewTab,
  ]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (onClick) onClick(e);
      if (isMobile) {
        handleOpenInMobile();
      } else {
        handleOpenInDesktop(e);
      }
    },
    [handleOpenInDesktop, handleOpenInMobile, isMobile, onClick],
  );

  return (
    <div
      className={cn(styles.wrapper, className)}
      style={{ top: `${top}%`, left: `${left}%`, right: 'unset', bottom: 'unset' }}
      data-testid='pin'
    >
      <div
        {...restProps}
        className={cn(styles.pin, { [styles.active]: visible })}
        onClick={handleClick}
      />

      {visible && !isMobile && (
        <Suspense fallback={null}>
          <IdeasPopup
            className={styles.popup}
            product={product}
            ref={refPopup}
            style={{ ...popupPosition, width: `${popupWidth}px` }}
            listTitle={analyticsTitle}
            withoutCurrency={priceWithoutCurrency}
            onClickProductLink={onClickProductLink}
            inNewTab={inNewTab}
          />
        </Suspense>
      )}
    </div>
  );
};

export default memo(Pin);
